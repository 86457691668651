var heroSlider = new Swiper('.heroSlider', {
    disableOnInteraction: false,
    loop: true,
    speed: 1000,
    effect: 'fade',
    autoplay: {
     delay: 5000
    },
    fadeEffect: {
      crossFade: true
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
});


$(".heroSlider").hover(function(){
    heroSlider.autoplay.stop();
    }, function(){
    heroSlider.autoplay.start();
});

var newDetailSlider = new Swiper('.newDetailSlider', {
    disableOnInteraction: false,
    loop: true,
    speed: 1000,
    effect: 'fade',
    autoplay: {
     delay: 5000
    },
    fadeEffect: {
      crossFade: true
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
});


$(".newDetailSlider").hover(function(){
    newDetailSlider.autoplay.stop();
    }, function(){
    newDetailSlider.autoplay.start();
});

var careerSlider = new Swiper('.careerSlider', {
    disableOnInteraction: false,
    loop: true,
    speed: 1000,
    effect: 'fade',
    autoplay: {
     delay: 5000
    },
    fadeEffect: {
      crossFade: true
    },
    navigation: {
        nextEl: '.swiper-button-next',
        prevEl: '.swiper-button-prev',
    }
});


$(".careerSlider").hover(function(){
    careerSlider.autoplay.stop();
    }, function(){
    careerSlider.autoplay.start();
});




var newsSlide = new Swiper('.newsSlide', {
    disableOnInteraction: false,
      speed: 1000,
      slidesPerView: 2,
      disableOnInteraction: false,
      loop: true,
      autoplay: {
        delay: 5000,
      },
      breakpoints: {
            991: {
              slidesPerView: 1
            }
        }
    });

$(".newsSlide").hover(function(){
    newsSlide.autoplay.stop();
    }, function(){
    newsSlide.autoplay.start();
});







jQuery(document).ready(function($) {


$(".expandable > ul > li > h2").on('click', function(event) {
    $(this).parent().siblings().removeClass('active-expand').find(".expand-text").slideUp(500);
    $(this).parent().addClass('active-expand').find(".expand-text").slideDown(500);
});

$(".expandable > ul > li > h3").on('click', function(event) {
    $(this).parent().siblings().removeClass('active-expand').find(".expand-inner").slideUp(500);
    $(this).parent().addClass('active-expand').find(".expand-inner").slideDown(500);
});





$.fn.extend({
  animateCss: function(animationName, callback) {
    var animationEnd = (function(el) {
      var animations = {
        animation: 'animationend',
        OAnimation: 'oAnimationEnd',
        MozAnimation: 'mozAnimationEnd',
        WebkitAnimation: 'webkitAnimationEnd',
      };

      for (var t in animations) {
        if (el.style[t] !== undefined) {
          return animations[t];
        }
      }
    })(document.createElement('div'));

    this.addClass('animated ' + animationName).one(animationEnd, function() {
      $(this).removeClass('animated ' + animationName);

      if (typeof callback === 'function') callback();
    });

    return this;
  },
});

$(".lightyellow,.lightyellow1,.lightgreen,.lightgreen1").hover(function() {
    $(this).find(".circle1").animateCss('bounceIn');
}, function() {
    /* Stuff to do when the mouse leaves the element */
});



    $("[data-scroll]").each(function(index, el) {
        var $elm = $(this);
        var $offset =  $elm.data("anim-offset");
        $elmWatcher = scrollMonitor.create($elm, $offset);

        $elmWatcher.enterViewport(function() {
            $elm.addClass('animActive');
            $elm.find("[data-anim]").each(function() {
                $(this).css("animation-delay",+$(this).data("anim-delay")+"s" ).addClass("animated "+ $(this).data("anim")).css("visibility","visible");
                //console.log($(this).attr("class"));
            });
        });
    });


    $(".scrollto").on("click", function() {
        $("html,body").animate({ scrollTop: $("#aboutus").position().top - 80 }, 1000);
    });


    var slideout = new Slideout({
        'panel': document.getElementById('panel'),
        'menu': document.getElementById('menumob'),
        'padding': 256,
        'tolerance': 70
    });


    var fixed = document.querySelector('.headermob');

    slideout.on('translate', function(translated) {
        fixed.style.transform = 'translateX(' + translated + 'px)';
    });

    slideout.on('beforeopen', function() {
        fixed.style.transition = 'transform 300ms ease';
        fixed.style.transform = 'translateX(256px)';
        $('.hamburger').addClass('h-active');
    });

    slideout.on('beforeclose', function() {
        fixed.style.transition = 'transform 300ms ease';
        fixed.style.transform = 'translateX(0px)';
        $('.hamburger').removeClass('h-active');
    });

    slideout.on('open', function() {
        fixed.style.transition = '';
    });

    slideout.on('close', function() {
        fixed.style.transition = '';
    });


    // makes the parallax elements
    function parallaxIt() {
        // create variables
        var $fwindow = $(window);
        var scrollTop = window.pageYOffset || document.documentElement.scrollTop;

        var $contents = [];
        var $backgrounds = [];

        // for each of content parallax element
        $('[data-type="content"]').each(function(index, e) {
            var $contentObj = $(this);

            $contentObj.__speed = ($contentObj.data('speed') || 1);
            $contentObj.__fgOffset = ($contentObj.data('offset') || $contentObj.offset().top);
            $contents.push($contentObj);
        });

        // for each of background parallax element
        $('[data-type="background"]').each(function() {
            var $backgroundObj = $(this);

            $backgroundObj.__speed = ($backgroundObj.data('speed') || 1);
            $backgroundObj.__fgOffset = ($contentObj.data('offset') || $contentObj.offset().top);
            $backgrounds.push($backgroundObj);
        });

        // update positions
        $fwindow.on('scroll resize', function() {
            scrollTop = window.pageYOffset || document.documentElement.scrollTop;

            $contents.forEach(function($contentObj) {
                var yPos = $contentObj.__fgOffset - scrollTop / $contentObj.__speed;

                $contentObj.css('transform', "translateY(" + -yPos + "px)");
                //console.log("translateY("+ yPos+"px)");
            })

            $backgrounds.forEach(function($backgroundObj) {
                var yPos = -((scrollTop - $backgroundObj.__fgOffset) / $backgroundObj.__speed);

                $backgroundObj.css({
                    backgroundPosition: '50% ' + yPos + 'px'
                });
            });
        });

        // triggers winodw scroll for refresh
        $fwindow.trigger('scroll');
    };

    parallaxIt();




    var cl = $(".main-nav .nav").children().not(".logo").clone(true)

    $('#menumob').prepend(cl).wrapInner('<ul></ul>');

    // mobile menu
    $('.hamburger').click(function(event) {
        $(this).toggleClass('h-active');
        slideout.toggle();
    });


    function fixedHeader() {
        var $winPos = $(window).scrollTop();
        var $header = $(".header-main");
        if ($winPos > 50) {
            $header.addClass('header-active');
        } else {
            $header.removeClass('header-active');
        }
    }

    fixedHeader();

    // fixed header
    $(window).scroll(function(event) {
        fixedHeader();
    });


    $(function() {
        $(window).scroll(function() {
            if ($(this).scrollTop() > 2500) {
                $('.gotop').fadeIn();
            } else {
                $('.gotop').fadeOut();
            }
        });

        // scroll body to 0px on click
        $('.gotop').click(function() {
            $('body,html').animate({
                scrollTop: 0
            }, 800);
            return false;
        });
    });









});